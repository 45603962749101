import React, { FC } from 'react'
import styled from 'styled-components'

import { space } from '../../../constants/length'
import { Primary, Secondary } from '../../../constants/newColor'

const Toggle = styled.div`
  height: 2rem;
  width: 2rem;
  position: relative;

  & :after {
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    border: 1px solid ${Primary};
    border-radius: 50%;
    box-shadow: 0px 1px 0 black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
const StyledItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${space.s}px;
  &:last-child {
    margin-bottom: 0;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  color: ${Secondary};
  display: flex;
  font-weight: 300;
  font-size: 1rem;
  line-height: 32px;
`
const ColorfulContentWrapper = styled.div`
  color: ${Primary};
`
interface ColorfulContentProps {
  content: string
}
const ColorfulContent: FC<ColorfulContentProps> = props => {
  const { content } = props
  return <ColorfulContentWrapper>{content}</ColorfulContentWrapper>
}

interface ListItemBaseProps {
  content: string
}
type ListItemProps = FC<ListItemBaseProps> & {
  ColorfulContent: FC<ColorfulContentProps>
}

const ListItem: ListItemProps = props => {
  const { content, children } = props
  return (
    <StyledItem>
      <Toggle />
      <TextWrapper>
        {children}
        {children && `、`}
        {content}
      </TextWrapper>
    </StyledItem>
  )
}

ListItem.ColorfulContent = ColorfulContent

export default ListItem
