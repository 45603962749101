import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import consultImage from '../../assets/static/consult/consult6.jpg'
import periodontal1Image from '../../assets/static/service/periodontal1.png'
import periodontal2Image from '../../assets/static/service/periodontal2.png'
import periodontal3Image from '../../assets/static/service/periodontal3.png'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { textSize } from '../../constants/text'
import ContentSection from './components/ContentSection'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const PeriodTitle = styled.div`
  font-size: ${textSize.h3Web}px;
  line-height: 30px;
  margin-bottom: ${space.xs + space.s}px;
`
const ListGroup = styled.div`
  margin-top: ${space.m}px;
`

/** 牙周治療 */
const Periodontal = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.periodontal' }),
      path: '/service/periodontal'
    }
  ]

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.periodontal' })}>
      <Seo
        title='『我自己治好了我的牙周病？』牙周病專業治療首選-日不落牙醫集團'
        metaDescription='牙周病非老人專利！不注重口腔清潔的人都是高風險族群，專業牙周病治療首選日不落牙醫集團，台北、新竹、台中、台南的權威級醫療團隊加上親切仔細的診療過程，再嚴重的牙周問題也能迎刃而解。'
      />
      <StyledContentTitle>{formatMessage({ id: 'service.periodontal' })}</StyledContentTitle>
      <ContentSection title='什麼是牙周病？'>
        <p>牙周病並不是老人病，只要你刷牙習慣不對、不注重口腔清潔、沒有定期洗牙習慣，你！都是牙周病的高風險群。</p>
        <p>
          牙周病是因為食物殘渣與細菌黏附在牙齒表面形成牙菌斑，產生刺激牙齦組織的毒性物質，堆積進而形成牙結石會破壞牙齦、牙周韌帶、牙槽骨等牙周組織，嚴重者齒槽骨會因此萎縮、牙齒失去支撐而鬆動，最後甚至導致牙齒脫落。
        </p>
      </ContentSection>
      <ContentSection title='牙周病三階段'>
        <ContentSection imageUrl={periodontal1Image} imageAlt='第一階段：牙菌斑/牙結石'>
          <PeriodTitle>第一階段：牙菌斑/牙結石</PeriodTitle>
          <p>
            <strong>口乾舌燥、口腔異味</strong>
          </p>
          <p>牙菌斑就是口腔中的細菌，只要拿張乾淨的衛生紙，擦過你牙齒表面那層黃黃的東西，就是它了！</p>
          <p>牙菌斑使口中酸鹼性升高，進而造成牙結石堆積，牙結石會刺激牙周組織、引起牙齦發炎、形成「牙周囊袋」，當你聽到這個名詞就知道事情大條了！</p>
        </ContentSection>
        <ContentSection imageUrl={periodontal2Image} imageAlt='第二階段：牙齦炎'>
          <PeriodTitle>第二階段：牙齦炎</PeriodTitle>
          <p>
            <strong>刷牙流血、牙齦紅腫、牙齦萎縮</strong>
          </p>
          <p>當「牙周囊袋」形成，就是我們說的「牙齦炎」，囊袋就真的是個袋子在你牙齒周圍，裝更多的食物殘渣、牙菌斑、牙結石在裡面，繼續向下挖深破壞牙齒根基。</p>
        </ContentSection>
        <ContentSection imageUrl={periodontal3Image} imageAlt='第三階段：牙周病'>
          <PeriodTitle>第三階段：牙周病</PeriodTitle>
          <p>
            <strong>牙根暴露、牙齒鬆動、牙齒敏感</strong>
          </p>
          <p>當牙周囊袋中的牙菌斑、牙結石們侵害到牙周韌帶、或齒槽骨時，牙齒就開始鬆動、咬東西變得痠軟無力，甚至嚴重時還有可能發生膿腫、發燒等現象。</p>
        </ContentSection>
      </ContentSection>
      <ContentSection title='如何自我檢查牙周病？'>
        <p>如果超過 3 點符合，請盡速約診就醫檢查。</p>
        <ListGroup>
          <ListItem content='刷牙、或用牙線棒時有血跡' />
          <ListItem content='口臭、口腔氣味不好' />
          <ListItem content='牙齦萎縮、牙根露出，照鏡子發現牙齒變長了' />
          <ListItem content='牙齦摸起來腫腫脹脹、甚至發膿' />
          <ListItem content='牙齒開始搖起來了，吃東西咀嚼的時候怪怪的' />
          <ListItem content='牙齦有悶悶的感覺' />
        </ListGroup>
      </ContentSection>
      <ContentSection title='最簡單的牙周病預防方式'>
        <p>
          <strong>正確的刷牙方式：</strong>若不知道該如何正確刷牙，可找日亞美小天使教學唷！
        </p>
        <p>
          <strong>注意口腔清潔：</strong>養成飯後刷牙每天使用牙線的習慣。
        </p>
        <p>
          <strong>每半年定期洗牙及口腔檢查</strong>
        </p>
      </ContentSection>
    </ServiceLayout>
  )
}

export default Periodontal
